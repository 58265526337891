import React, { useEffect } from 'react'
import {
  GalleryContainer,
  DescriptionContainer,
  DeckContainer
} from "./styles";
import DeckController from "./DeckController";

import CartelOrigenes from "../../images/monkey/menu_05.png"
import VerVideo from "../../images/monkey/Origenes/VerVideo.png"
import VolverMenu from "../../images/monkey/Origenes/VolverAlMenu.png"
import BackgroundImage from "../../images/monkey/Origenes/fondo.png"
import StarsAnimation from "./StarsAnimation";

import Img1 from "../../images/monkey/Origenes/Galerìa-Origenes-01.png";
import Img2 from "../../images/monkey/Origenes/Galerìa-Origenes-02.png";
import Img3 from "../../images/monkey/Origenes/Galerìa-Origenes-03.png";
import Img4 from "../../images/monkey/Origenes/Galerìa-Origenes-04.png";
import Img5 from "../../images/monkey/Origenes/Galerìa-Origenes-05.png";
import Img6 from "../../images/monkey/Origenes/Galerìa-Origenes-06.png";
import Img7 from "../../images/monkey/Origenes/Galerìa-Origenes-07.png";
import Img8 from "../../images/monkey/Origenes/Galerìa-Origenes-08.png";
import Img9 from "../../images/monkey/Origenes/Galerìa-Origenes-09.png";
import Img10 from "../../images/monkey/Origenes/Galerìa-Origenes-10.png";
import Img11 from "../../images/monkey/Origenes/Galerìa-Origenes-11.png";
import Img12 from "../../images/monkey/Origenes/Galerìa-Origenes-12.png";
import Img13 from "../../images/monkey/Origenes/Galerìa-Origenes-13.png";
import Img14 from "../../images/monkey/Origenes/Galerìa-Origenes-14.png";
import Img15 from "../../images/monkey/Origenes/Galerìa-Origenes-15.png";
import Img16 from "../../images/monkey/Origenes/Galerìa-Origenes-16.png";
import Img17 from "../../images/monkey/Origenes/Galerìa-Origenes-17.png";

const Cards = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17
]

const Origenes = ({ toggle, id, menuId, type, Close, Activescroll }) => {
  const DescriptionController = () => {
    if (type) {
      Close()
    } else {
      document.getElementById(menuId).scrollIntoView(true)
    }
  }
  useEffect(() => {
    const Punter = document.getElementById('punter');
    if (Activescroll) {
      const Container = document.getElementById(id);
      let isScrolling;
      Container.addEventListener('scroll', function (event) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          Punter.classList.add("active-Swipe");
        }, 66);
        if (Punter.classList.contains("active-Swipe")) {
          Punter.classList.remove("active-Swipe");
        }
      }, false);
    }
    return () => {
      if (Punter.classList.contains("active-Swipe")) {
        Punter.classList.remove("active-Swipe");
      }
    }
  }, [Activescroll, id])
  return (
    <GalleryContainer backgroundImage={BackgroundImage} id={id} type={type}>
      <DescriptionContainer type={type}>
        <div className="poster-contaier">
          <img src={CartelOrigenes} alt={`Monkey ${CartelOrigenes}`} />
        </div>
        <div className="poster-description">
          <p>
            ORÍGENES E HISTORIA DEL CARTEL CHICHA<br /><br />
            La década de los ochenta fue el momento en el que la música chicha y su gráfica explotó en el Perú. La música chicha fue la banda sonora de los migrantes que mezclaron los instrumentos occidentales con sus melodías vernaculares; paralelamente, a partir de esta música, desarrollaron una estética visual que comenzó con el diseño de las tapas de los discos y de ahí continuó con la elaboración de carteles serigráficos que se pegaban en las calles para publicitar los conciertos de los principales grupos chicheros.<br /><br />
            En un principio, los carteles usaban colores básicos. El uso de colores fosforescentes se dará recién alrededor del año 88, cuando se empezaron a fabricar en el Perú tintas flúor para serigrafía en papel. En el caso de Monky, su primer afiche con colores fosforescentes se diseñó para el concierto de Tongo y Chacalón en “El Hueco”.<br /><br />
            El uso de estos colores se hizo popular y aparecieron los talleres de carteles chicha de Delia Flores, de “Vanessa Publicidad”, de Fortunato Urcuhuaranga, de “Viusa Publicidad”, entre muchos otros. No sólo en Lima, sino en el resto del país. Años después, Edinson y Elliot “Tupac” Urcuhuaranga se independizarán del padre y crearon el taller “CEA Publicidad”. Posteriormente, Elliot Tupac se convertirá en el artista más representativo de una estética que ya tiene cuatro décadas de historia.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monkey ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monkey ${VolverMenu}`} />
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monkey ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monkey ${VolverMenu}`} />
          </button>
        </div>
      </DescriptionContainer>
      <div className="deck-super_container">
        <DeckContainer className="deck-container" type={type}>
          <DeckController Cards={Cards} type={type}/>
        </DeckContainer>
      </div>
    </GalleryContainer>
  )
}

export default Origenes
