import React, { useEffect } from 'react'
import {
  GalleryContainer,
  DescriptionContainer,
  DeckContainer
} from "./styles";
import DeckController from "./DeckController";

import CartelesyBanderasIMG from "../../images/monkey/carteles_02.png"
import VerVideo from "../../images/monkey/Carteles/VerVideo.png"
import VolverMenu from "../../images/monkey/Carteles/VolverAlMenu.png"
import BackgroundImage from "../../images/monkey/Carteles/fondo.png"
import StarsAnimation from "./StarsAnimation";

import Img1 from "../../images/monkey/Carteles/Galerìa-Carteles-01.png";
import Img2 from "../../images/monkey/Carteles/Galerìa-Carteles-02.png";
import Img3 from "../../images/monkey/Carteles/Galerìa-Carteles-03.png";
import Img4 from "../../images/monkey/Carteles/Galerìa-Carteles-04.png";
import Img5 from "../../images/monkey/Carteles/Galerìa-Carteles-05.png";
import Img6 from "../../images/monkey/Carteles/Galerìa-Carteles-06.png";
import Img7 from "../../images/monkey/Carteles/Galerìa-Carteles-07.png";
import Img8 from "../../images/monkey/Carteles/Galerìa-Carteles-08.png";
import Img9 from "../../images/monkey/Carteles/Galerìa-Carteles-09.png";
import Img10 from "../../images/monkey/Carteles/Galerìa-Carteles-10.png";
import Img11 from "../../images/monkey/Carteles/Galerìa-Carteles-11.png";
import Img12 from "../../images/monkey/Carteles/Galerìa-Carteles-12.png";
import Img13 from "../../images/monkey/Carteles/Galerìa-Carteles-13.png";
import Img14 from "../../images/monkey/Carteles/Galerìa-Carteles-14.png";
import Img15 from "../../images/monkey/Carteles/Galerìa-Carteles-15.png";
import Img16 from "../../images/monkey/Carteles/Galerìa-Carteles-16.png";
import Img17 from "../../images/monkey/Carteles/Galerìa-Carteles-17.png";
import Img18 from "../../images/monkey/Carteles/Galerìa-Carteles-18.png";
import Img19 from "../../images/monkey/Carteles/Galerìa-Carteles-19.png";
import Img20 from "../../images/monkey/Carteles/Galerìa-Carteles-20.png";
import Img21 from "../../images/monkey/Carteles/Galerìa-Carteles-21.png";
import Img22 from "../../images/monkey/Carteles/Galerìa-Carteles-22.png";
import Img23 from "../../images/monkey/Carteles/Galerìa-Carteles-23.png";
import Img24 from "../../images/monkey/Carteles/Galerìa-Carteles-24.png";
import Img25 from "../../images/monkey/Carteles/Galerìa-Carteles-25.png";
import Img26 from "../../images/monkey/Carteles/Galerìa-Carteles-26.png";
import Img27 from "../../images/monkey/Carteles/Galerìa-Carteles-27.png";
import Img28 from "../../images/monkey/Carteles/Galerìa-Carteles-28.png";
import Img29 from "../../images/monkey/Carteles/Galerìa-Carteles-29.png";
import Img30 from "../../images/monkey/Carteles/Galerìa-Carteles-30.png";
import Img31 from "../../images/monkey/Carteles/Galerìa-Carteles-31.png";
import Img32 from "../../images/monkey/Carteles/Galerìa-Carteles-32.png";
import Img33 from "../../images/monkey/Carteles/Galerìa-Carteles-33.png";
import Img34 from "../../images/monkey/Carteles/Galerìa-Carteles-34.png";
import Img35 from "../../images/monkey/Carteles/Galerìa-Carteles-35.png";
import Img36 from "../../images/monkey/Carteles/Galerìa-Carteles-36.png";
import Img37 from "../../images/monkey/Carteles/Galerìa-Carteles-37.png";
import Img38 from "../../images/monkey/Carteles/Galerìa-Carteles-38.png";
import Img39 from "../../images/monkey/Carteles/Galerìa-Carteles-39.png";
import Img40 from "../../images/monkey/Carteles/Galerìa-Carteles-40.png";
import Img41 from "../../images/monkey/Carteles/Galerìa-Carteles-41.png";
import Img42 from "../../images/monkey/Carteles/Galerìa-Carteles-42.png";
import Img43 from "../../images/monkey/Carteles/Galerìa-Carteles-43.png";
import Img44 from "../../images/monkey/Carteles/Galerìa-Carteles-44.png";

const Cards = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29,
  Img30,
  Img31,
  Img32,
  Img33,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
  Img41,
  Img42,
  Img43,
  Img44
]

const CartelesyBanderas = ({ toggle, id, menuId, type, Close, Activescroll }) => {
  const DescriptionController = () => {
    if (type) {
      Close()
    } else {
      document.getElementById(menuId).scrollIntoView(true)
    }
  }
  useEffect(() => {
    const Punter = document.getElementById('punter');
    if (Activescroll) {
      const Container = document.getElementById(id);
      let isScrolling;
      Container.addEventListener('scroll', function (event) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          Punter.classList.add("active-Swipe");
        }, 66);
        if (Punter.classList.contains("active-Swipe")) {
          Punter.classList.remove("active-Swipe");
        }
      }, false);
    }
    return () => {
      if (Punter.classList.contains("active-Swipe")) {
        Punter.classList.remove("active-Swipe");
      }          
    }
  }, [Activescroll, id])
  return (
    <GalleryContainer backgroundImage={BackgroundImage} id={id} type={type}>
      <DescriptionContainer type={type} className="description-container-responsive">
        <div className="poster-contaier">
          <img src={CartelesyBanderasIMG} alt={CartelesyBanderasIMG} />
        </div>
        <div className="poster-description">
          <p>
            CARTELES Y BANDEROLAS<br /><br />
            A comienzos de la década de los noventas, Monky, ante la competencia de los nuevos talleres de carteles chicha, decide iniciarse en el pintado de banderolas para conciertos.<br /><br />
            Las banderolas existían desde comienzos de los ochentas, pero eran básicamente telas negras en las cuales se pegaban letras de papel pintadas con esmalte fosforescente y que se siguen usando hasta el presente. La diferencia con las banderolas de Monky es que, en estas, las letras eran totalmente pintadas sobre la tela y, en algunos casos, incluían un retrato del artista.<br /><br />
            En la actualidad, Monky pinta banderolas y hace afiches a pedido, ya no solo para conciertos sino para distintos eventos y clientes, que pueden ser desde un restaurante en Australia, un Dj en Alemania, un cortamontes en Ayacucho, o un matrimonio en La Molina.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo}/>
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu}/>
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo}/>
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu}/>
          </button>
        </div>
      </DescriptionContainer>
      <div className="deck-super_container">
        <DeckContainer className="deck-container" type={type}>
          <DeckController Cards={Cards} type={type}/>
        </DeckContainer>
      </div>
      <DescriptionContainer className="description-container">
        <div className="poster-contaier">
          <img src={CartelesyBanderasIMG} alt="monkey carteles y banderas"/>
        </div>
        <div className="poster-description">
          <p>
            CARTELES Y BANDEROLAS<br /><br />
            A comienzos de la década de los noventas, Monky, ante la competencia de los nuevos talleres de carteles chicha, decide iniciarse en el pintado de banderolas para conciertos.<br /><br />
            Las banderolas existían desde comienzos de los ochentas, pero eran básicamente telas negras en las cuales se pegaban letras de papel pintadas con esmalte fosforescente y que se siguen usando hasta el presente. La diferencia con las banderolas de Monky es que, en estas, las letras eran totalmente pintadas sobre la tela y, en algunos casos, incluían un retrato del artista.<br /><br />
            En la actualidad, Monky pinta banderolas y hace afiches a pedido, ya no solo para conciertos sino para distintos eventos y clientes, que pueden ser desde un restaurante en Australia, un Dj en Alemania, un cortamontes en Ayacucho, o un matrimonio en La Molina.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo}/>
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu}/>
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo}/>
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu}/>
          </button>
        </div>
      </DescriptionContainer>
    </GalleryContainer>
  )
}

export default CartelesyBanderas
