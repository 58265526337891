import React from 'react'
import {
  CreditsContainer,
  CreditsTextContainer,
  ButtonToBeginning
} from "./styles";
import CreditosIMG from "../../images/monkey/Creditos-03.png";
import StarsAnimation from "./StarsAnimation";
import VolverMenu from "../../images/monkey/carteles_04_edited.png"

const Creditos = () => {
  return (
    <CreditsContainer id="monkeyCreditos">
      <CreditsTextContainer>
        <div className="extra-container">
          <div className="title">
            <img src={CreditosIMG} alt="Monkey Creditos" />
          </div>
          <div className="text-container">
            <p>
              Curaduría General:<br /><br />

              Alfredo Villar Lurquin (1971)<br /><br />

              Estudió Lingüística y literatura en la Pontificia Universidad Católica del Perú e Historia del Arte en la Universidad Nacional Mayor de San Marcos. Como curador de arte ha publicado distintos libros y realizado diversas exposiciones de arte amazónico como El milagro verde (con Christian Bendayán), Usko Ayar: la escuela de las visiones y de arte popular chicha como A mí qué chicha y El pueblo es una nostalgia que algún día vencerá del fotógrafo vernacular Nicolás Torres.<br /><br />

              También es guionista de cómics e investigador. Su novela gráfica sobre los primeros años de la guerra interna en el Perú, Rupay, ganó la Beca Rockefeller el año 2004 y fue publicada por primera vez en el 2008. Ha sido editada, también, en España (2010), posteriormente en Francia y Brasil (2016) y recientemente en Austria (2018). Uno de sus últimos trabajos como investigador y curador culminó con la exposición artística y el libro: Búmm! Historieta y humor gráfico en el Perú (1978-1992).<br /><br />

              Material audiovisual:<br /><br />

              Fotografía:<br /><br />
              Yefferson Huamán<br />
              Archivo Alfredo Villar<br />
              Archivo Pedro Rojas<br />
              Marie-Charlotte Béja<br /><br />

              Video:<br /><br />
              Marie-Charlotte Béja<br />
            </p>
          </div>
          <ButtonToBeginning
            onClick={() => document.getElementById('monkeymenu').scrollIntoView(true)}
          >
            <StarsAnimation
              primaryColor='red'
              secondaryprimaryColor='red'
            />
            <img src={VolverMenu} alt={VolverMenu} />
          </ButtonToBeginning>
        </div>
      </CreditsTextContainer>
    </CreditsContainer>
  )
}

export default Creditos
