import React from 'react'
import {
  PortraitContainer,
  TopOrnament,
  BottomOrnament,
  MonkeyPortraitTextContainer,
  VideoButton,
  PortraitElementsContainer,
  GradientPortraitContainer,
  VideoButtonResponsive
} from "./styles";
import TopOrnamentImage from "../../images/monkey/portada_Inicio_04.png";
import BottomOrnamentImage from "../../images/monkey/portada_Inicio_05.png";
import MonkeyLogo from "../../images/monkey/portada_Inicio_02.png";
import ButtonVideo from "../../images/monkey/portada_Inicio_03.png";
import StarsAnimation from "./StarsAnimation";

const portrait = ({ toggle }) => {
  return (
    <PortraitContainer id="monkeyInicio">
      <TopOrnament src={TopOrnamentImage} />
      <PortraitElementsContainer>
        <MonkeyPortraitTextContainer>
          <div>
            <img src={MonkeyLogo} alt="Monkey logo" />
          </div>
          <p><em>Pedro Tolomeo Rojas, más conocido en la escena artística como "Monky", es un creador autodidacta que, a comienzos de la decada de los ochenta, se inicio en el diseño de los afiches chicha. Su historia es la de un migrante que, como muchos, vino a la capital para realizar sus sueños pero trayendo consigo todo el color, el sabor y la alegría de los andes peruanos.</em></p>
        </MonkeyPortraitTextContainer>
        <VideoButton onClick={toggle}>
          <StarsAnimation
            primaryColor='#B806D1'
            secondaryColor='#B806D1'
          />
          <img src={ButtonVideo} alt="button video" />
        </VideoButton>
      </PortraitElementsContainer>
      <BottomOrnament src={BottomOrnamentImage} />
      <GradientPortraitContainer />
      <VideoButtonResponsive onClick={toggle}>
        <StarsAnimation
          primaryColor='#B806D1'
          secondaryColor='#B806D1'
        />
        <img src={ButtonVideo} alt="button video" />
      </VideoButtonResponsive>
    </PortraitContainer>
  )
}

export default portrait
