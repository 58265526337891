import React from 'react'
import { animated, interpolate } from 'react-spring'
import { InView } from 'react-intersection-observer';

function Deck({
  deckProperty,
  cards,
  trans,
  bind,
  id,
  setInview,
  HandleShowMouse,
}) {
  const ChangeView = (isInView) => {
    setInview(isInView)
    HandleShowMouse(true)
  }
  return (
    <InView 
      className="InViewContainer"
      id={id}
      onChange={inView => ChangeView(inView)}
    >
      { 
        deckProperty.map(({ x, y, rot, scale }, i) => (
          <animated.div key={i} style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}>
            <animated.div {...bind(i)} style={{ transform: interpolate([rot, scale], trans) }}>
              <div className="image-deck-container">
                <img alt={`monkey deck ${cards[i]}`} src={cards[i]} style={{ pointerEvents: 'none' }} />
              </div>
            </animated.div>
          </animated.div>
        ))
      }
    </InView>
  )
}

export default Deck;
