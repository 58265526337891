import React from 'react'
import { AnimatedDiv } from "./styles";

const StarsAnimation = ({ primaryColor = 'rgb(235,69,61)', secondaryColor = 'rgb(235,69,61)' }) => {
  return (
    <AnimatedDiv className="animated">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="eoU5FX2u4Wj1"
        viewBox="0 0 740 400"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <g id="eoU5FX2u4Wj2_to" transform="translate(433.288949,57.409648)">
          <g id="eoU5FX2u4Wj2_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj2_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj2" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={primaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj3_to" transform="translate(297.027493,59.484696)">
          <g id="eoU5FX2u4Wj3_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj3_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj3" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={secondaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj4_to" style={{ "offsetPath": "path('M216.792321,60.868061Q121.338964,118.925974,140.858758,243.472246')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj4_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj4_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj4" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={primaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj5_to" style={{ "offsetPath": "path('M216.792321,60.868061C292.389176,79.259480,400.455413,163.551851,393.325500,200C379.168590,226.747181,259.506653,264.687551,265.330007,301.843526')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj5_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj5_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj5" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={secondaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj6_to" style={{ "offsetPath": "path('M85.859800,39.914269C268.470668,74.021032,206.229148,165.093965,199.099236,201.542114C184.942325,228.289295,373.699019,315.575333,344.349935,328.035767')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj6_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj6_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj6" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={primaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj7_to" style={{ "offsetPath": "path('M573.008818,58.793013C605.120336,89.304417,624.816537,108.091398,612.449683,137.425736C598.317482,168.272523,648.070598,214.503697,602.210997,258.689261')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj7_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj7_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj7" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={secondaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj8_to" style={{ "offsetPath": "path('M371.812421,80.072288C277.173477,172.571144,684.028431,156.065414,668.886020,199.277538C654.753819,230.124325,401.045974,221.905184,355.186373,266.090748')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj8_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj8_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj8" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={primaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj9_to" style={{ "offsetPath": "path('M268.488903,55.107113C205.257759,17.142798,461.676705,122.189776,378.472555,149.282014C336.959195,209.120617,401.045974,221.905184,355.186373,266.090748')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj9_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj9_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj9" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={secondaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
        <g id="eoU5FX2u4Wj10_to" style={{ "offsetPath": "path('M179.466042,61.912959C115.837028,23.514605,65.269165,168.201549,114.873090,202.304817C133.108942,217.331510,288.186508,285.232751,242.326908,329.418315')", "offsetRotate": "0deg" }}>
          <g id="eoU5FX2u4Wj10_tr" transform="rotate(-66.729296)">
            <g id="eoU5FX2u4Wj10_ts" transform="scale(-0.011196,-0.011196)">
              <polygon id="eoU5FX2u4Wj10" points="0,-63.185870 8.463065,-20.431646 44.679157,-44.679157 20.431646,-8.463065 63.185870,0 20.431646,8.463065 44.679157,44.679157 8.463065,20.431646 0,63.185870 -8.463065,20.431646 -44.679157,44.679157 -20.431646,8.463065 -63.185870,0 -20.431646,-8.463065 -44.679157,-44.679157 -8.463065,-20.431646 0,-63.185870" transform="translate(0,0)" fill={primaryColor} stroke="none" strokeWidth="0" />
            </g>
          </g>
        </g>
      </svg>
    </AnimatedDiv >
  )
}

export default StarsAnimation
