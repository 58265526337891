import React, { useState } from 'react';
import { useSprings } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { useDebouncedEffect } from '../../hooks/useDebounced';
import Hand from "../../images/Icons/hand.png"
import Deck from "./Deck";

const DeckController = ({ Cards, id, type }) => {
  const [ShowMouse, setShowMouse] = useState();
  const [Inview, setInview] = useState();

  const HandleShowMouse = (value) => {
    setShowMouse(value)
  }
  useDebouncedEffect(() => HandleShowMouse(false), 5000, [Inview]);

  const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

  const to = (i, element) => {
    if (!element) {
      return { x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 }
    } else if (element <= i) {
      return { x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 }
    } else {
      return { x: 200 + window.innerWidth, rot: 67.74805915628843, scale: 1, delay: undefined, config: { friction: 50, tension: 200 } }
    }
  }
  const from = i => {
    return { x: 0, rot: 0, scale: 1.5, y: -1000 }
  }
  const [gone] = useState(() => new Set())
  const [deckProperty, set] = useSprings(Cards.length, i => ({ ...to(i, null), from: from(i) }))

  const bind = useGesture(

    ({ args: [index], down, delta: [xDelta], direction: [xDir], velocity }) => {
      const Puntero = document.getElementById('punter');
      if((!Puntero.classList.contains("active-Swipe")) && type === 'mobile' ){
        return { x: 0, rot: 0, scale: 1, delay: undefined, config: { friction: 50, tension: 500 } };
      }
      const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
      if (!down && trigger) {
        gone.add(index)
      }
      set(i => {
        if (index !== i) return
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0)
        const scale = down ? 1.1 : 1
        return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
      })
      if (!down && gone.size === Cards.length) {
        setTimeout(() => gone.clear() || set(i => to(i)), 600)
      }
    })

  return (
    <>
      <Deck
        deckProperty={deckProperty}
        cards={Cards}
        trans={trans}
        id={id}
        bind={bind}
        setInview={setInview}
        HandleShowMouse={HandleShowMouse}
      />
      <div className={"handswipe " + (ShowMouse ? '' : 'handswipe-out')}>
        <img src={Hand} alt="hand" />
      </div>
    </>
  )
}

export default DeckController
