import React from 'react'
import {
  ModalMonkey
} from "./styles";

const Modal = ({ toggle, children, isOpen }) => {
  return (
    <ModalMonkey backdrop={true} show={isOpen} centered={true} onHide={toggle}>
      {children}
    </ModalMonkey>
  )
}

export default Modal
