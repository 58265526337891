import React, { useEffect } from 'react'
import {
  GalleryContainer,
  DescriptionContainer,
  DeckContainer
} from "./styles";
import DeckController from "./DeckController";

import EnLasCallesIMG from "../../images/monkey/menu_08.png"
import VerVideo from "../../images/monkey/Calles/VerVideo.png"
import VolverMenu from "../../images/monkey/Calles/VolverAlMenu.png"
import StarsAnimation from "./StarsAnimation";
import BackgroundImage from "../../images/monkey/Calles/fondo.png"

import Img1 from "../../images/monkey/Calles/Galerìa-Calles-01.png";
import Img2 from "../../images/monkey/Calles/Galerìa-Calles-02.png";
import Img3 from "../../images/monkey/Calles/Galerìa-Calles-03.png";
import Img4 from "../../images/monkey/Calles/Galerìa-Calles-04.png";
import Img5 from "../../images/monkey/Calles/Galerìa-Calles-05.png";
import Img6 from "../../images/monkey/Calles/Galerìa-Calles-06.png";
import Img7 from "../../images/monkey/Calles/Galerìa-Calles-07.png";
import Img8 from "../../images/monkey/Calles/Galerìa-Calles-08.png";
import Img9 from "../../images/monkey/Calles/Galerìa-Calles-09.png";
import Img10 from "../../images/monkey/Calles/Galerìa-Calles-10.png";
import Img11 from "../../images/monkey/Calles/Galerìa-Calles-11.png";
import Img12 from "../../images/monkey/Calles/Galerìa-Calles-12.png";
import Img13 from "../../images/monkey/Calles/Galerìa-Calles-13.png";
import Img14 from "../../images/monkey/Calles/Galerìa-Calles-14.png";
import Img15 from "../../images/monkey/Calles/Galerìa-Calles-15.png";

const Cards = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15
]

const CartelesyBanderas = ({ toggle, id, menuId, type, Close, Activescroll }) => {
  const DescriptionController = () => {
    if (type) {
      Close()
    } else {
      document.getElementById(menuId).scrollIntoView(true)
    }
  }
  useEffect(() => {
    const Punter = document.getElementById('punter');
    if (Activescroll) {
      const Container = document.getElementById(id);
      let isScrolling;
      Container.addEventListener('scroll', function (event) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          Punter.classList.add("active-Swipe");
        }, 66);
        if (Punter.classList.contains("active-Swipe")) {
          Punter.classList.remove("active-Swipe");
        }
      }, false);
    }
    return () => {
      if (Punter.classList.contains("active-Swipe")) {
        Punter.classList.remove("active-Swipe");
      }
    }
  }, [Activescroll, id])
  return (
    <GalleryContainer id={id} backgroundImage={BackgroundImage} type={type}>
      <DescriptionContainer type={type}>
        <div className="poster-contaier">
          <img src={EnLasCallesIMG} alt={EnLasCallesIMG} />
        </div>
        <div className="poster-description">
          <p>
            EN LAS CALLES<br /><br />
            El arte chicha es uno de los más democráticos que existe, porque su principal lugar de exposición es en las calles. Es un arte que le ha dado color a las paredes grises de Lima, aunque su apropiación del espacio público es considerada, en algunos municipios, como ilegal.<br /><br />
            Monky ha tenido muchas anécdotas en su itinerario callejero, ya sea pegando afiches, colgando banderolas o pintando paredes. Las calles de Lima y el Perú también tienen sus historias y sus artistas, como Monky, cuyas creaciones, por suerte, ahora son valoradas cada vez más.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo} />
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu} />
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={VerVideo} />
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={VolverMenu} />
          </button>
        </div>
      </DescriptionContainer>
      <div className="deck-super_container">
        <DeckContainer className="deck-container" type={type}>
          <DeckController type={type} Cards={Cards} />
        </DeckContainer>
      </div>
    </GalleryContainer>
  )
}

export default CartelesyBanderas
