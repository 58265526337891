import React, { useState } from 'react'
import Layout from '../share/layout';
import Portrait from "./Portrait";
import Menu from "./Menu";
import CartelesyBanderas from "./CartelesyBanderas";
import Origenes from "./Origenes";
import EnLasCalles from "./EnLasCalles";
import TalleresPremiosyViajes from "./TalleresPremiosyViajes";
import Creditos from "./Creditos";
import Modal from "./Modal";
import Modalvideo_1 from "../../images/monkey/Modalvideo-01.png"
import Modalvideo_2 from "../../images/monkey/Modalvideo-02.png"
import Modalvideo_3 from "../../images/monkey/Modalvideo-03.png"
import Modalvideo_4 from "../../images/monkey/Modalvideo-04.png"
import Modalvideo_5 from "../../images/monkey/Modalvideo-05.png"
import ShareButton from "../share/ShareButton";
import { useLocation } from "@reach/router";
import { ModalFullScreen } from "./styles";

const Monkey = () => {
  let location = useLocation();
  const [ModalShow, setModalShow] = useState({
    PionerodecartelesChicha: false,
    OrigeneseHistoriadeCartelChicha: false,
    CartelesyBanderolas: false,
    EnlasCalles: false,
    TalleresPremiosyViaje: false,
    CardOrigenes: false,
    CardCarteles: false,
    CardEnLasCalles: false,
    CardTalleres: false
  })
  const ShowModal = (param) => {
    const {
      PionerodecartelesChicha,
      OrigeneseHistoriadeCartelChicha,
      CartelesyBanderolas,
      EnlasCalles,
      TalleresPremiosyViaje,
      CardOrigenes,
      CardCarteles,
      CardEnLasCalles,
      CardTalleres
    } = ModalShow
    if (param === 1) {
      setModalShow({ ...ModalShow, PionerodecartelesChicha: !PionerodecartelesChicha })
    } else if (param === 2) {
      setModalShow({ ...ModalShow, OrigeneseHistoriadeCartelChicha: !OrigeneseHistoriadeCartelChicha })
    } else if (param === 3) {
      setModalShow({ ...ModalShow, CartelesyBanderolas: !CartelesyBanderolas })
    } else if (param === 4) {
      setModalShow({ ...ModalShow, EnlasCalles: !EnlasCalles })
    } else if (param === 5) {
      setModalShow({ ...ModalShow, TalleresPremiosyViaje: !TalleresPremiosyViaje })
    } else if (param === 6) {
      setModalShow({ ...ModalShow, CardOrigenes: !CardOrigenes })
    } else if (param === 7) {
      setModalShow({ ...ModalShow, CardCarteles: !CardCarteles })
    } else if (param === 8) {
      setModalShow({ ...ModalShow, CardEnLasCalles: !CardEnLasCalles })
    } else if (param === 9) {
      setModalShow({ ...ModalShow, CardTalleres: !CardTalleres })
    }
  }

  return (
    <Layout
      title="Exhibición virtual – Monky, Pionero de los carteles chicha - UPC Cultural"
      metaTitle="Exhibición virtual – Monky, Pionero de los carteles chicha - UPC Cultural"
      description="Exhibición virtual Monky, Pionero de los carteles chicha – Pionero de los carteles chicha"
      subtitile="Exhibición virtual Monky, Pionero de los carteles chicha – Pionero de los carteles chicha"
      metaDescripcin="Exhibición virtual Monky, Pionero de los carteles chicha – Pionero de los carteles chicha"
      imgurl="//images.ctfassets.net/bv5rnjawitjg/4aqZjov2mSDbdh0jTnzA20/b5732c89d4e5882fc09c050152088a7f/monky_800_x_800-01.png"
      keywords="Exhibición virtual Monky Carteles Chicha Gráfica Alfredo Villar Pedro Tolomeo Arte Artes Visuales Perú Lima"
    >
      <ShareButton url={location.pathname} />
      <h1 style={{ display: 'none' }} className="SEO-HIDDEN " id="punter">Monkey</h1>
      <h2 style={{ display: 'none' }} className="SEO-HIDDEN">Pionero de los carteles chicha</h2>
      <Portrait
        toggle={() => ShowModal(1)}
      />
      <Menu id="monkeymenu" ShowModal={ShowModal} />
      <Origenes
        toggle={() => ShowModal(2)}
        id='OrigeneseHistoriadeCartelChicha'
        menuId='monkeymenu'
      />
      <CartelesyBanderas
        toggle={() => ShowModal(3)}
        id='CartelesyBanderolas'
        menuId='monkeymenu'
      />
      <EnLasCalles
        toggle={() => ShowModal(4)}
        id='EnlasCalles'
        menuId='monkeymenu'
      />
      <TalleresPremiosyViajes
        toggle={() => ShowModal(5)}
        id='TalleresPremiosyViaje'
        menuId='monkeymenu'
      />
      <Creditos />
      <Modal
        toggle={() => ShowModal(5)}
        isOpen={ModalShow.TalleresPremiosyViaje}
      >
        <button onClick={() => ShowModal(5)} className="title">
          <img src={Modalvideo_1} alt="Monky Close" />
        </button>
        <div className="container-iframe">
          <iframe src='https://player.vimeo.com/video/563434311' title="Talleres, Premios y Viajes" />
        </div>
      </Modal>
      <Modal
        toggle={() => ShowModal(3)}
        isOpen={ModalShow.CartelesyBanderolas}
      >
        <button onClick={() => ShowModal(3)} className="title">
          <img src={Modalvideo_2} alt="Monky Close" />
        </button>
        <div className="container-iframe">
          <iframe src='https://player.vimeo.com/video/563433186' title="Carteles y Banderolas" />
        </div>
      </Modal>
      <Modal
        toggle={() => ShowModal(2)}
        isOpen={ModalShow.OrigeneseHistoriadeCartelChicha}
      >
        <button onClick={() => ShowModal(2)} className="title">
          <img src={Modalvideo_3} alt="Monky Close" />
        </button>
        <div className="container-iframe">
          <iframe src='https://player.vimeo.com/video/563432026' title="Origenes e Historia del Cartel Chicha" />
        </div>
      </Modal>
      <Modal
        toggle={() => ShowModal(4)}
        isOpen={ModalShow.EnlasCalles}
      >
        <button onClick={() => ShowModal(4)} className="title">
          <img src={Modalvideo_4} alt="Monky Close" />
        </button>
        <div className="container-iframe">
          <iframe src='https://player.vimeo.com/video/563434914' title="En las Calles" />
        </div>
      </Modal>
      <Modal
        toggle={() => ShowModal(1)}
        isOpen={ModalShow.PionerodecartelesChicha}
      >
        <button onClick={() => ShowModal(1)} className="title">
          <img src={Modalvideo_5} alt="Monky Close" />
        </button>
        <div className="container-iframe">
          <iframe src='https://player.vimeo.com/video/563430521' title="Monky, Pionero de los carteles Chicha" />
        </div>
      </Modal>
      <ModalFullScreen backdrop={false} show={ModalShow.CardOrigenes} centered={true} onHide={() => ShowModal(6)}>
        <Origenes
          type='mobile'
          toggle={() => ShowModal(2)}
          Close={() => ShowModal(6)}
          id='OrigeneseHistoriadeCartelChicha2'
          menuId='monkeymenu'
          Activescroll={true}
        />
      </ModalFullScreen>
      <ModalFullScreen backdrop={false} show={ModalShow.CardCarteles} centered={true} onHide={() => ShowModal(7)} id="evento">
        <CartelesyBanderas
          type='mobile'
          toggle={() => ShowModal(3)}
          Close={() => ShowModal(7)}
          id='CartelesyBanderolas2'
          menuId='monkeymenu'
          Activescroll={true}
        />
      </ModalFullScreen>
      <ModalFullScreen backdrop={false} show={ModalShow.CardEnLasCalles} centered={true} onHide={() => ShowModal(8)}>
        <EnLasCalles
          type='mobile'
          toggle={() => ShowModal(4)}
          Close={() => ShowModal(8)}
          id='EnlasCalles2'
          menuId='monkeymenu'
          Activescroll={true}
        />
      </ModalFullScreen>
      <ModalFullScreen backdrop={false} show={ModalShow.CardTalleres} centered={true} onHide={() => ShowModal(9)}>
        <TalleresPremiosyViajes
          type='mobile'
          toggle={() => ShowModal(5)}
          Close={() => ShowModal(9)}
          id='TalleresPremiosyViaje2'
          menuId='monkeymenu'
          Activescroll={true}
        />
      </ModalFullScreen>
    </Layout>
  )
}

export default Monkey
