import React, { useEffect } from 'react'
import {
  GalleryContainer,
  DescriptionContainer,
  DeckContainer
} from "./styles";
import DeckController from "./DeckController";

import TalleresPremiosyViajesIMG from "../../images/monkey/menu_09.png"
import VerVideo from "../../images/monkey/Talleres/VerVideo.png"
import VolverMenu from "../../images/monkey/Talleres/VolverAlMenu.png"
import BackgroundImage from "../../images/monkey/Talleres/fondo.png"

import StarsAnimation from "./StarsAnimation";

import Img1 from "../../images/monkey/Talleres/Galeria-Talleres-01.png";
import Img2 from "../../images/monkey/Talleres/Galeria-Talleres-02.png";
import Img3 from "../../images/monkey/Talleres/Galeria-Talleres-03.png";
import Img4 from "../../images/monkey/Talleres/Galeria-Talleres-04.png";
import Img5 from "../../images/monkey/Talleres/Galeria-Talleres-05.png";
import Img6 from "../../images/monkey/Talleres/Galeria-Talleres-06.png";
import Img7 from "../../images/monkey/Talleres/Galeria-Talleres-07.png";
import Img8 from "../../images/monkey/Talleres/Galeria-Talleres-08.png";
import Img9 from "../../images/monkey/Talleres/Galeria-Talleres-09.png";
import Img10 from "../../images/monkey/Talleres/Galeria-Talleres-10.png";
import Img11 from "../../images/monkey/Talleres/Galeria-Talleres-11.png";
import Img12 from "../../images/monkey/Talleres/Galeria-Talleres-12.png";
import Img13 from "../../images/monkey/Talleres/Galeria-Talleres-13.png";
import Img14 from "../../images/monkey/Talleres/Galeria-Talleres-14.png";

const Cards = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14
]

const TalleresPremiosyViajes = ({ toggle, id, menuId, type, Close, Activescroll }) => {
  const DescriptionController = () => {
    if (type) {
      Close()
    } else {
      document.getElementById(menuId).scrollIntoView(true)
    }
  }

  useEffect(() => {
    const Punter = document.getElementById('punter');
    if (Activescroll) {
      const Container = document.getElementById(id);
      let isScrolling;
      Container.addEventListener('scroll', function (event) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          Punter.classList.add("active-Swipe");
        }, 66);
        if (Punter.classList.contains("active-Swipe")) {
          Punter.classList.remove("active-Swipe");
        }
      }, false);
    }
    return () => {
      if (Punter.classList.contains("active-Swipe")) {
        Punter.classList.remove("active-Swipe");
      }          
    }
  }, [Activescroll, id])

  return (
    <GalleryContainer backgroundImage={BackgroundImage} id={id} type={type}>
      <DescriptionContainer type={type} className="description-container-responsive">
        <div className="poster-contaier">
          <img src={TalleresPremiosyViajesIMG} alt="Monky Talleres y premios" />
        </div>
        <div className="poster-description">
          <p>
            TALLERES, PREMIOS Y VIAJES<br /> <br />
            En el nuevo milenio, la aparición de la gigantografía fue una estocada fatal para muchos artistas callejeros, entre ellos, Monky. Esto cambiaría el año 2013 con la exposición colectiva “A mí que chicha”, con la cual Monky volvería a pintar y hacer carteles y banderolas, comenzando también con el algo que era novedad para él: dictar talleres de arte chicha.<br /><br />
            Al año siguiente, se realiza la segunda versión de “A mí que chicha” y un cartel que él diseña para este evento gana el primer premio en un concurso organizado por el festival internacional de diseño TRIMARCHI que se da todos los años en Mar del Plata, Argentina. Monky viaja para recibir el premio y, a la vez, es invitado para dar talleres en la Universidad de la Plata.<br /><br />
            El 2015 Monky es invitado, junto con medio centenar de artistas peruanos más, al Smithsonian Folklife Festival dedicado a nuestro país que se realizó en Washington DC. Ahí, Monky dio todos los días talleres gratuitos e, incluso, preparó pachamanca. Al año siguiente, es invitado a dar talleres en Santiago de Chile.<br /><br />
            Desde su regreso, hasta la actualidad, es invitado a dar distintos talleres en distintas universidades, instituciones y municipalidades. Monky recibió recientemente un premio de Estímulo Económico del Ministerio de Cultura del Perú por su labor como artista chicha.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monky ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monky ${VolverMenu}`} />
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monky ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monky ${VolverMenu}`} />
          </button>
        </div>
      </DescriptionContainer>
      <div className="deck-super_container">
        <DeckContainer className="deck-container" type={type} id={id}>
          <DeckController Cards={Cards} type={type} />
        </DeckContainer>
      </div>
      <DescriptionContainer className="description-container">
        <div className="poster-contaier">
          <img src={TalleresPremiosyViajesIMG} alt="Monky Talleres y premios"/>
        </div>
        <div className="poster-description">
          <p>
            TALLERES, PREMIOS Y VIAJES<br /> <br />
            En el nuevo milenio, la aparición de la gigantografía fue una estocada fatal para muchos artistas callejeros, entre ellos, Monky. Esto cambiaría el año 2013 con la exposición colectiva “A mí que chicha”, con la cual Monky volvería a pintar y hacer carteles y banderolas, comenzando también con el algo que era novedad para él: dictar talleres de arte chicha.<br /><br />
            Al año siguiente, se realiza la segunda versión de “A mí que chicha” y un cartel que él diseña para este evento gana el primer premio en un concurso organizado por el festival internacional de diseño TRIMARCHI que se da todos los años en Mar del Plata, Argentina. Monky viaja para recibir el premio y, a la vez, es invitado para dar talleres en la Universidad de la Plata.<br /><br />
            El 2015 Monky es invitado, junto con medio centenar de artistas peruanos más, al Smithsonian Folklife Festival dedicado a nuestro país que se realizó en Washington DC. Ahí, Monky dio todos los días talleres gratuitos e, incluso, preparó pachamanca. Al año siguiente, es invitado a dar talleres en Santiago de Chile.<br /><br />
            Desde su regreso, hasta la actualidad, es invitado a dar distintos talleres en distintas universidades, instituciones y municipalidades. Monky recibió recientemente un premio de Estímulo Económico del Ministerio de Cultura del Perú por su labor como artista chicha.<br /><br />
          </p>
        </div>
        <div className="button-decks-container">
          <button onClick={toggle} className="VerVideo">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monky ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenu">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monky ${VolverMenu}`} />
          </button>
          <button onClick={toggle} className="VerVideoAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#B806D1'
            />
            <img src={VerVideo} alt={`Monky ${VerVideo}`} />
          </button>
          <button onClick={DescriptionController} className="VolverMenuAbsolute">
            <StarsAnimation
              primaryColor='#fff'
              secondaryColor='#fff'
            />
            <img src={VolverMenu} alt={`Monky ${VolverMenu}`} />
          </button>
        </div>
      </DescriptionContainer>
    </GalleryContainer>
  )
}

export default TalleresPremiosyViajes
