import React from 'react'
import {
  MenuContainer,
  ButtonToBeginning,
  ButtonToCredits,
  MenuLogo,
  MenuButtonsContainer,
  MenuBottomOrnament,
  NumberButton
} from "./styles";
import { useSpring, animated } from 'react-spring'
import Inicio from "../../images/monkey/menu_03.png";
import Creditos from "../../images/monkey/menu_04.png";
import MenuImg from "../../images/monkey/menu_02.png"

import Origenes from "../../images/monkey/menu_05.png";
import Carteles from "../../images/monkey/menu_07.png";
import Calles from "../../images/monkey/menu_08.png";
import Taller from "../../images/monkey/menu_09.png";
import StarsAnimation from "./StarsAnimation";

import PhoneNumber from "../../images/monkey/number.png";

import { useWindowSize } from "../../hooks/useWindowSize";

const calc = (x, y) => [-(y - window.innerHeight / 3) / 20, (x - window.innerWidth / 3) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const Menu = ({ id, ShowModal }) => {
  const { width } = useWindowSize();
  const [origen, setOrigin] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));
  const [carteles, setCarteles] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
  const [calles, setCalles] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
  const [taller, setTaller] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
  const HandleMenuAction = (toScroll) => {
    if (width >= 991) {
      document.getElementById(toScroll).scrollIntoView(true)
    } else {
      if (toScroll === "OrigeneseHistoriadeCartelChicha") {
        ShowModal(6)
      } else if (toScroll === "CartelesyBanderolas") {
        ShowModal(7)
      } else if (toScroll === "EnlasCalles") {
        ShowModal(8)
      } else if (toScroll === "TalleresPremiosyViaje") {
        ShowModal(9)
      }
    }
  }
  return (
    <MenuContainer id={id}>
      <ButtonToBeginning
        onClick={() => document.getElementById('monkeyInicio').scrollIntoView(true)}
      >
        <StarsAnimation
          primaryColor='red'
          secondaryprimaryColor='red'
        />
        <img src={Inicio} alt={`Monkey ${Inicio}`} />
      </ButtonToBeginning>
      <MenuLogo>
        <img src={MenuImg} alt={`Monkey ${MenuImg}`} />
      </MenuLogo>
      <ButtonToCredits
        onClick={() => document.getElementById('monkeyCreditos').scrollIntoView(true)}
      >
        <StarsAnimation
          primaryColor='#B806D1'
          secondaryColor='#B806D1'
        />
        <img src={Creditos} alt={`Monkey ${Creditos}`} />
      </ButtonToCredits >
      <MenuButtonsContainer>
        <animated.button
          onMouseMove={({ clientX: x, clientY: y }) => setOrigin({ xys: calc(x, y) })}
          onMouseLeave={() => setOrigin({ xys: [0, 0, 1] })}
          style={{
            transform: origen.xys.interpolate(trans),
            display: 'block',
            position: 'relative',
          }}
          onClick={({ clientX: x, clientY: y }) => {
            setOrigin({ xys: [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1] })
            HandleMenuAction('OrigeneseHistoriadeCartelChicha');
          }}
        >
          <img src={Origenes} alt={`Monkey ${Origenes}`} />
        </animated.button>
        <animated.button
          onMouseMove={({ clientX: x, clientY: y }) => setCarteles({ xys: calc(x, y) })}
          onMouseLeave={() => setCarteles({ xys: [0, 0, 1] })}
          style={{ transform: carteles.xys.interpolate(trans) }}
          onClick={({ clientX: x, clientY: y }) => {
            setCarteles({ xys: [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1] })
            HandleMenuAction('CartelesyBanderolas');
          }}
        >
          <img src={Carteles} alt={`Monkey ${Carteles}`} />
        </animated.button>
        <animated.button
          onMouseMove={({ clientX: x, clientY: y }) => setCalles({ xys: calc(x, y) })}
          onMouseLeave={() => setCalles({ xys: [0, 0, 1] })}
          style={{ transform: calles.xys.interpolate(trans) }}
          onClick={({ clientX: x, clientY: y }) => {
            setCalles({ xys: [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1] })
            HandleMenuAction('EnlasCalles');
          }}
        >
          <img src={Calles} alt={`Monkey ${Calles}`} />
        </animated.button>
        <animated.button
          onMouseMove={({ clientX: x, clientY: y }) => setTaller({ xys: calc(x, y) })}
          onMouseLeave={() => setTaller({ xys: [0, 0, 1] })}
          style={{ transform: taller.xys.interpolate(trans) }}
          onClick={({ clientX: x, clientY: y }) => {
            setTaller({ xys: [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1] })
            HandleMenuAction('TalleresPremiosyViaje');
          }}
        >
          <img src={Taller} alt={`Monkey ${Taller}`} />
        </animated.button>
      </MenuButtonsContainer>
      <MenuBottomOrnament />
      <NumberButton>
        <img src={PhoneNumber} alt={`Monkey ${PhoneNumber}`} />
      </NumberButton>
    </MenuContainer >
  )
}

export default Menu
